module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coinsclone","short_name":"Coinsclone","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/coinsclone.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8811ee89efe4e1af9ec345f1a2308528"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1,"cacheName":"gatsby-plugin-offline-runtime-cache","expiration":{"maxEntries":200}}}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T22K58B","includeInDevelopment":false,"defer":true,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"routeChange"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
